.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  background-color: #f5f5f5;
}

.MuiContainer-root {
  padding-bottom: 2rem;
}
